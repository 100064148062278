import React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "/src/components/layouts/default"
import Seo from "/src/components/helpers/seo"

const page = ({ data }) => {
  const post = data.nodePage;
  const langPrefix = post.langcode === 'en' ? '' : '/'+post.langcode;
  return (
    <Layout>
     <Seo title={post.title} />
    <div className="langSwitcher">
    <Link to={langPrefix + '/'}>HOME</Link>
    {post.langcode !== 'en' && data.english !== null && <Link to={data.english.path.alias}>English</Link>}
    {post.langcode !== 'pl' && data.polish !== null && <Link to={'/pl' + data.polish.path.alias}>Polish</Link>}
    {post.langcode !== 'fr' && data.french !== null && <Link to={'/fr' + data.french.path.alias}>French</Link>}
    </div>
      <h1>{post.title}</h1>
      <small>
        <em>{Date(post.created)}</em>
      </small>
      {post.body !== null && <div dangerouslySetInnerHTML={{ __html: post.body.value }}></div>}
    </Layout>
  );
};

export default page;

export const query = graphql`
  query ($drupal_internal__nid: Int, $langcode: String) {
    nodePage(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: $langcode }
    ) {
      langcode
      title
      body {
        value
      }
      created
      path {
        alias
      }
    }
    english: nodePage(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: "en" }
    ) {
      path {
        alias
      }
    }
    polish: nodePage(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: "pl" }
    ) {
      path {
        alias
      }
    }
    french: nodePage(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: "fr" }
    ) {
      path {
        alias
      }
    }
  }
`;
